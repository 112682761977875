export const variantInput = {
  initialnext: {
    x: 20,
    opacity: 0,
  },
  initialback: {
    x: -20,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: [0.3, 0.23, 0.37, 0.92],
    },
  },
  exit: {
    scale: 0.95,
    y: 10,
    opacity: 0,
    transition: {
      duration: 0.25,
      ease: [0.08, 0.54, 0.5, 0.85],
    },
  },
};
