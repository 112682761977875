import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useWindowScroll, useWindowSize, useLocation } from "react-use";
import { Link } from "gatsby";
import Logo from "../svg/logo";
import * as styles from "./logolink.module.scss";

function Logolink() {
  const { height } = useWindowSize();
  const { y } = useWindowScroll();
  const [hideNav, setHideNav] = useState(true);
  const currentLocation = useLocation();

  useEffect(() => {
    if (height - 250 > y && currentLocation.pathname === "/") {
      setHideNav(false);
    } else {
      setHideNav(true);
    }
  }, [height, y, currentLocation]);

  return (
    <motion.div
      className={styles.home}
      animate={{
        y: hideNav ? "3.5rem" : "2rem",
      }}
      transition={{
        delay: 0.15,
        duration: 0.4,
        ease: [0.35, 0.1, 0.5, 1],
      }}
    >
      <Link to="/" className={styles.logo}>
        <Logo />
      </Link>
    </motion.div>
  );
}

export default Logolink;
