import React from "react";
import * as styles from "./mobilemenu.module.scss";
import { Link } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";
import { IoHomeOutline, IoCallOutline, IoMailOutline } from "react-icons/io5";
import { Iconsone, Iconsthree, Iconstwo } from "./svg/icons";
import {
  Layerfive,
  Layerfour,
  Layerone,
  Layerthree,
  Layertwo,
} from "./svg/background";
import cx from "classnames";
import { TbRoute } from "react-icons/tb";

function MobileMenu({ isOpen, closeMenu, formOpen }) {
  const openForm = () => {
    closeMenu();
    setTimeout(() => {
      formOpen();
    }, 150);
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.nav
          className={styles.container}
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: -10 }}
          exit={{ opacity: 0, y: 10, transition: { delay: 0.3 } }}
          transition={{ duration: 0.3 }}
        >
          <div className={styles.pagelinks}>
            <div className={styles.services}>
              <h2 className={styles.title}>Наши услуги</h2>
              <Link
                to="/indoortowtruck"
                className={styles.link}
                activeClassName={cx(styles.link, styles.active)}
                onClick={() => closeMenu()}
              >
                <Iconsone className={styles.icon} />
                <p className={styles.label}>Крытый эвакуатор</p>

                <Layerfour className={styles.layer} />
              </Link>

              <Link
                to="/carcarrier"
                className={styles.link}
                activeClassName={cx(styles.link, styles.active)}
                onClick={() => closeMenu()}
              >
                <Iconstwo className={styles.icon} />

                <p className={styles.label}>Автовоз</p>
                <Layerone className={styles.layer} />
              </Link>

              <Link
                to="/minicarcarrier"
                className={styles.link}
                activeClassName={cx(styles.link, styles.active)}
                onClick={() => closeMenu()}
              >
                <Iconsthree className={styles.icon} />
                <p className={styles.label}>Мини автовоз</p>
                <Layerfive className={styles.layer} />
              </Link>

              <Link
                to="/transportationofthecar"
                className={styles.link}
                activeClassName={cx(styles.link, styles.active)}
                onClick={() => closeMenu()}
              >
                <Iconsone className={styles.icon} />
                <p className={styles.label}>Траснспортировка авто</p>
                <Layerthree className={styles.layer} />
              </Link>

              <Link
                to="/directions"
                className={styles.link}
                activeClassName={cx(styles.link, styles.active)}
                onClick={() => closeMenu()}
              >
                <TbRoute className={styles.icon} style={{ opacity: 0.6 }} />
                <p className={styles.label}>Актуальные направления</p>
                <Layertwo className={styles.layer} />
              </Link>
            </div>
            <div className={styles.footer}>
              <Link
                to="/"
                className={styles.linkhome}
                activeClassName={cx(styles.linkhome, styles.active)}
                onClick={() => closeMenu()}
              >
                <IoHomeOutline className={styles.icon} />
                <p className={styles.label}>Главная страница</p>
              </Link>
              <button className={styles.link} onClick={() => openForm()}>
                <IoMailOutline className={styles.icon} />
                <p className={styles.label}>Оставить заявку</p>
              </button>
              <a href="tel:+78005551163" className={styles.link}>
                <IoCallOutline className={styles.icon} />
                <p className={styles.label}>Звонок</p>
              </a>
            </div>
          </div>
        </motion.nav>
      )}
    </AnimatePresence>
  );
}

export default MobileMenu;
