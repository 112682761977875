import React, { useEffect, useRef } from "react";
import Lottie from "lottie-react";
import completeLottie from "../../data/complete.json";
import * as styles from "./complete.module.scss";

function Complete({ name }) {
  const lottieRef = useRef();

  useEffect(() => {
    lottieRef.current.pause();
    lottieRef.current.setSpeed(1.2);
    lottieRef.current.playSegments([1, 115], true);

    setTimeout(() => {
      lottieRef.current.play();
    }, 200);
  }, []);

  return (
    <div className={styles.container}>
      <Lottie
        animationData={completeLottie}
        lottieRef={lottieRef}
        className={styles.animation}
        loop={false}
      />
      <h2>
        Спасибо за заявку
        <br />
        {name}!
      </h2>
      <p>Скоро мы свяжемся с Вами!</p>
    </div>
  );
}

export default Complete;
