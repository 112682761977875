import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { useWindowSize } from "react-use";
import ScrollTop from "../components/scrollTop";
import Form from "./callback/form";
import Header from "./header";
import MobileNav from "./mobileNav";
import MobileMenu from "./mobileMenu";

function Layout({ children }) {
  const [isFormOpen, setisFormOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { width } = useWindowSize();

  return (
    <>
      <Form isOpen={isFormOpen} />
      {width >= 1024 && <Header />}
      {width < 1024 && (
        <MobileMenu
          isOpen={isMenuOpen}
          closeMenu={() => setIsMenuOpen(false)}
          formOpen={() => setisFormOpen(true)}
        />
      )}
      <AnimatePresence exitBeforeEnter initial={false}>
        {children}
      </AnimatePresence>
      {width < 1024 && (
        <MobileNav
          openForm={() => {
            setIsMenuOpen(false);
            setisFormOpen(!isFormOpen);
          }}
          openMenu={() => {
            setisFormOpen(false);
            setIsMenuOpen(!isMenuOpen);
          }}
          clickHome={() => {
            setisFormOpen(false);
            setIsMenuOpen(false);
          }}
          formIsOpen={isFormOpen}
          menuIsOpen={isMenuOpen}
        />
      )}
      <ScrollTop />
    </>
  );
}

export default Layout;
