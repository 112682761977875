import React from "react";

export function Iconsone(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 485 296">
      <rect
        x={6}
        y={182}
        width={13}
        height={30}
        rx={6.5}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <circle
        cx={393.95}
        cy={238.92}
        r={39.09}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M408.82 239.47a15.41 15.41 0 0 1-15.42 15.41c-20.45-.81-20.44-30 0-30.83a15.42 15.42 0 0 1 15.42 15.42Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeDasharray="3.03 3.03"
      />
      <circle
        cx={122.6}
        cy={239.84}
        r={39.09}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M137.46 240.39a15.41 15.41 0 0 1-15.41 15.42c-20.45-.81-20.45-30 0-30.83a15.41 15.41 0 0 1 15.41 15.41Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeDasharray="3.03 3.03"
      />
      <rect
        x={168.29}
        y={200.46}
        width={173.58}
        height={42.5}
        rx={10.65}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <path
        d="M196 12.11h258.86a21.5 21.5 0 0 1 21.5 21.5v158.5h-304V35.74A23.63 23.63 0 0 1 196 12.11Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={5}
      />
      <rect
        x={25.86}
        y={203.61}
        width={40}
        height={44}
        rx={6.52}
        transform="rotate(90 45.86 225.61)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        d="m70.86 219.61-33-1a26.54 26.54 0 0 1-13-6c-10.12-8.8-9.14-22.35-9-24q2.51-39 5-78l16-9 29-53C62.54 39.26 61.12 35.3 61 35a3.49 3.49 0 0 1-.16-.43c0-.42 66.66 0 72.77 0h.29c8.82.09 15.69 5.73 17.94 8 7 7 6.24 18.51 6 23-1 19.9-.67 62.11 0 120a160.29 160.29 0 0 0-36 0c-17.61 2-31.09 3.54-41 14a40.93 40.93 0 0 0-9.98 20.04Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M125.47 135.32h-20.72a2.19 2.19 0 0 1-1-.16 2.43 2.43 0 0 1-.87-.6 2.83 2.83 0 0 1-.59-.94 3.15 3.15 0 0 1 0-2.26 3.05 3.05 0 0 1 .59-.94 2.56 2.56 0 0 1 .87-.6 2.33 2.33 0 0 1 1-.16h20.72a2.31 2.31 0 0 1 1 .16 2.47 2.47 0 0 1 .87.6 2.72 2.72 0 0 1 .59.94 3.15 3.15 0 0 1 0 2.26 2.54 2.54 0 0 1-.59.94 2.35 2.35 0 0 1-.87.6 2.17 2.17 0 0 1-1 .16Z"
        fill="#fff"
        fillOpacity={0.7}
      />
      <path
        d="m137.39 121.32.6 25.52c.34 3.75.09 9.66-4.18 13.57s-10.7 4.09-19.13 4.34c-7.45.22-16-.13-16-.13a27.15 27.15 0 0 0-8.51.67 24.65 24.65 0 0 0-7.17 3.26c-9.11 6.06-8 14.31-17.94 21.71-5.43 4.08-9.41 4.33-10.75 4.35a16.21 16.21 0 0 1-9-2.72c-5.08-3.71-5.27-10.27-5.38-19-.16-12.37-.1-22.68 0-30.4M78.57 49.61h63v66.48h-51.2l-15.73 13.52H41.86a178.66 178.66 0 0 1 11.8-38.31 192.16 192.16 0 0 1 24.91-41.69Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
    </svg>
  );
}

export function Iconsthree(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 527 325">
      <path fill="none" d="M25.03 34.75h486.82v271.49H25.03z" />
      <path
        d="M117.47 74.53a125.34 125.34 0 0 0 13.43-.17 6.18 6.18 0 0 0 2.7-1.11 6.34 6.34 0 0 0 1.91-2.25c.39-1.49-1.33-3.57-2.14-5.35a19.17 19.17 0 0 1-2.71-5.45c-.43-4 0-8.12-.23-12.15a12.81 12.81 0 0 0-25.61.62c-.17 3.63 0 7.25 0 10.88 0 2.75.39 5.37-3.32 6.57a5.26 5.26 0 0 0-1.58 2.12 5.14 5.14 0 0 0-.31 2.63 6.53 6.53 0 0 0 4.55 3.45 124.78 124.78 0 0 0 13.4.15Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={5}
      />
      <circle
        cx={121.46}
        cy={264.29}
        r={41.04}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M137.07 264.86a16.18 16.18 0 0 1-16.18 16.19c-21.47-.85-21.47-31.52 0-32.37a16.18 16.18 0 0 1 16.18 16.18Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeDasharray="2.99 2.99"
      />
      <circle
        cx={323.03}
        cy={263.47}
        r={41.04}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M338.64 264.05a16.18 16.18 0 0 1-16.18 16.18c-21.47-.85-21.47-31.52 0-32.37a16.19 16.19 0 0 1 16.18 16.19Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeDasharray="2.99 2.99"
      />
      <rect
        x={139.03}
        y={217.91}
        width={278.33}
        height={42.5}
        rx={10.65}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <path
        d="m149.53 127.75 12.5-2.5v-88H492v61h20v14h-20v59h18v15h-17v23H174"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={5}
      />
      <path
        d="M150.53 129.75v-45c-13-1-35.95-2.38-59-1-5.69.34-14.15 1-21 7-6 5.2-7.85 11.93-9 16-4.16 14.64-9.16 31.29-15 51l-12 13v66l-7 14 16 18h36"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={5}
      />
      <path
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M87.53 93.75h52v62h-52zM77.53 155.75v-59l-4 4-17 55Z"
      />
    </svg>
  );
}

export function Iconstwo(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 916 339">
      <path
        d="M122 165h-20.76a2.21 2.21 0 0 1-1-.16 2.43 2.43 0 0 1-.87-.6 2.83 2.83 0 0 1-.59-.94 3.15 3.15 0 0 1 0-2.26 3.05 3.05 0 0 1 .59-.94 2.43 2.43 0 0 1 .87-.6 2.21 2.21 0 0 1 1-.16H122a2.17 2.17 0 0 1 1 .16 2.47 2.47 0 0 1 .88.6 2.85 2.85 0 0 1 .58.94 3.15 3.15 0 0 1 0 2.26 2.66 2.66 0 0 1-.58.94 2.47 2.47 0 0 1-.88.6 2.17 2.17 0 0 1-1 .16Z"
        fill="#fff"
        fillOpacity={0.7}
      />
      <path
        d="M183.63 31H791.5A21.5 21.5 0 0 1 813 52.5V208H160V54.63A23.63 23.63 0 0 1 183.63 31Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={5}
      />
      <rect
        x={531.5}
        y={194.5}
        width={250.5}
        height={42.5}
        rx={10.65}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <rect
        x={787}
        y={147}
        width={38}
        height={51}
        rx={6.52}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <circle
        cx={615}
        cy={260}
        r={35.5}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <circle
        cx={709}
        cy={260}
        r={35.5}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M629.5 260.5a14 14 0 0 1-14 14c-18.57-.74-18.57-27.27 0-28a14 14 0 0 1 14 14ZM722.5 260.5a14 14 0 0 1-14 14c-18.57-.74-18.57-27.27 0-28a14 14 0 0 1 14 14Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeDasharray="2.93 2.93"
      />
      <rect
        x={184.5}
        y={214.5}
        width={32}
        height={65}
        rx={6.52}
        transform="rotate(90 200.5 247)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <circle
        cx={273}
        cy={260}
        r={35.5}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M287.5 260.5a14 14 0 0 1-14 14c-18.57-.74-18.57-27.27 0-28a14 14 0 0 1 14 14Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeDasharray="2.93 2.93"
      />
      <circle
        cx={126}
        cy={260}
        r={35.5}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M140.5 260.5a14 14 0 0 1-14 14c-18.57-.74-18.57-27.27 0-28a14 14 0 0 1 14 14Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeDasharray="2.93 2.93"
      />
      <rect
        x={52.42}
        y={196.84}
        width={282.58}
        height={42.5}
        rx={10.65}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <rect
        x={39}
        y={222}
        width={40}
        height={44}
        rx={6.52}
        transform="rotate(90 59 244)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <rect
        x={26}
        y={184}
        width={13}
        height={30}
        rx={6.5}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        d="m134 151 .5 23.5c.29 3.46.07 8.9-3.5 12.5s-9 3.77-16 4c-6.23.2-13.38-.12-13.38-.12a20.5 20.5 0 0 0-7.12.62 19.48 19.48 0 0 0-6 3c-7.62 5.58-6.73 13.18-15 20-4.55 3.75-7.87 4-9 4A12.57 12.57 0 0 1 57 216c-4.25-3.42-4.41-9.47-4.5-17.5-.13-11.4-.08-20.9 0-28M90 87h48v59H99l-12 12H62a178.35 178.35 0 0 1 28-71Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        d="m43 139 11-8 22-43c-2.49-4.08-2.64-7-2-9 4.34-13.8 50.93-9.67 58-9a26.05 26.05 0 0 1 12 8 26.68 26.68 0 0 1 6 18l1 113c-3.82-1.3-24-7.7-45 3a58.4 58.4 0 0 0-26 27H55a26.76 26.76 0 0 1-9-7 27.14 27.14 0 0 1-6-14 272.4 272.4 0 0 1 3-79Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
    </svg>
  );
}
export function Iconsfive(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 855 325">
      <path fill="none" d="M24.04 36.25h821.38v273.79H24.04z" />
      <path
        d="M747.41 169.66c-4.86 4.85-10.45 7.71-17.4 6.74s-5.79-7.1-6.88-11.37c-6-24.09-19.44-35-43.74-34.9s-38.1 11.27-42.79 35.73c-1.61 8.36-5.52 10.69-13.12 10.2q-17-1.06-33.83-1.62c-33.48-1-67 .75-100.48-2.23-8.19-.75-11.13-3.59-11.47-11.73-1.12-27.47-18-44.41-44-44-25.25.36-44.59 20.28-43.47 42.63.56 11-5.71 14-15.63 9.1-7.48-3.67-9.38-10.33-7.29-17.34a485.5 485.5 0 0 1 16.5-47.58c4.5-10.47 14.31-14.54 26-14.06 13.27.65 26.73 0 39.88 1.45 18.2 2 32.71-2.83 45.22-16.79 8.8-9.82 20.27-17.21 29.89-26.38a34.5 34.5 0 0 1 26.2-9.93Q625.47 39.2 700 40a26.07 26.07 0 0 1 22.67 11.49c9.31 13.16 18.66 26.3 28.5 39.08 7.8 10.15 9.72 21.81 10.74 33 .09 18.24-6.19 37.88-14.5 46.09Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={5}
      />
      <path
        d="M60 270.8a32.52 32.52 0 0 1-21.39-5.53c-9.72-7.08-11.73-18.21-12.61-23-4.15-22.98 10.53-44.61 20.71-58.27C63 162.22 115.53 91.93 185.22 71.86c0 0 8.84-4.32 69-2.65a10.33 10.33 0 0 1 7.44 2.43 10.73 10.73 0 0 1 1.93 3.81 11.43 11.43 0 0 1 .3 4.26c-.26 2.35-.26 77.65-.13 185.54l-96.63 2.35c-.82-4-6.58-29.53-29.94-41.6a50.67 50.67 0 0 0-32.85-5.43 48.1 48.1 0 0 0-19.44 8.8C66.32 242.7 61.34 263.72 60 270.8Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        d="M714.22 209.75a841.74 841.74 0 0 1 132.59 19.62q-14.76 18.15-29.5 36.31a29.72 29.72 0 0 0-23.67-6.54c-46 6.3-92 12.33-138 18.44-13.61 1.8-27.22 3.59-40.85 5.24-1.33.16-4.26.36-6.24-1.46-2.58-2.38-2-6.87-1.69-8.61l31.51-6.23q3.42-21.88 6.85-43.77l-372-9v-16a135.67 135.67 0 0 0 4.44-13.15s.72-44.27-2-129.3a60.26 60.26 0 0 1 .82-12c1.17-5.82 6.1-5.7 10.57-5.5 3.84.14 8.66-1.24 10.48 3.75 1.67 4.56-1.07 7.81-4.86 10-5.15 3-6.68 7.44-6.64 13.19.2 22.57 0 45.13.17 67.7 0 3.15-.48 6.74 2.77 8.78 20.47 12.87 34.9 32.07 51.3 49.11a30.38 30.38 0 0 0 10.61 7.41 31.24 31.24 0 0 0 12.77 2.26c27.56-.51 272.19 4.54 350.57 9.75ZM679 235.28c-4.66-.46-10.71-2.74-13.39 2.43a23.89 23.89 0 0 0-.73 18.83c2 5.5 7.44 4 11.62 3.39 31.91-4.55 63.79-9.33 95.67-14.05l-.49-1.34q-46.29-4.75-92.65-9.26Zm-384.11-79.34c-1.61-1.67-3.63-3.61-6.32-2.13s-2.17 4.15-2 6.47c.55 10.45 1.31 20.87 1.79 31.3.27 5.4 2.7 8.12 8.22 8.09h36.08l1.41-2.93q-19.57-20.41-39.18-40.8Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={5}
      />
      <circle
        cx={115.68}
        cy={263.17}
        r={41.04}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M132.29 262.75a16.19 16.19 0 0 1-16.19 16.19c-21.46-.86-21.46-31.52 0-32.37a16.18 16.18 0 0 1 16.19 16.18Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeDasharray="2.99 2.99"
      />
      <circle
        cx={544.69}
        cy={262.59}
        r={41.04}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M561.3 263.17a16.19 16.19 0 0 1-16.19 16.19c-21.47-.85-21.46-31.52 0-32.37a16.18 16.18 0 0 1 16.19 16.18Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeDasharray="2.99 2.99"
      />
      <circle
        cx={433.4}
        cy={164.57}
        r={33.68}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M446.2 165a13.27 13.27 0 0 1-13.28 13.28c-17.62-.7-17.61-25.87 0-26.56A13.27 13.27 0 0 1 446.2 165Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeDasharray="2.98 2.98"
      />
      <circle
        cx={677.89}
        cy={171.97}
        r={33.68}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M690.69 172.44a13.28 13.28 0 0 1-13.28 13.29c-17.62-.7-17.61-25.87 0-26.57a13.28 13.28 0 0 1 13.28 13.28Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeDasharray="2.98 2.98"
      />
      <rect
        x={267.33}
        y={231.82}
        width={203.39}
        height={42.5}
        rx={10.65}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <path
        d="M181.71 90.43h63v66.48h-51.2l-15.73 13.52H145a179.24 179.24 0 0 1 11.8-38.31 193.21 193.21 0 0 1 24.91-41.69ZM496.72 92.25l43-42 50-1-10 43ZM594.72 92.25l4-43h49l31 43ZM695.72 94.25l-32-43h46.09q16 22 31.91 44Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
    </svg>
  );
}
export function Iconsfour(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 916 339">
      <path
        d="M122 165h-20.76a2.21 2.21 0 0 1-1-.16 2.43 2.43 0 0 1-.87-.6 2.83 2.83 0 0 1-.59-.94 3.15 3.15 0 0 1 0-2.26 3.05 3.05 0 0 1 .59-.94 2.43 2.43 0 0 1 .87-.6 2.21 2.21 0 0 1 1-.16H122a2.17 2.17 0 0 1 1 .16 2.47 2.47 0 0 1 .88.6 2.85 2.85 0 0 1 .58.94 3.15 3.15 0 0 1 0 2.26 2.66 2.66 0 0 1-.58.94 2.47 2.47 0 0 1-.88.6 2.17 2.17 0 0 1-1 .16Z"
        fill="#fff"
        fillOpacity={0.7}
      />
      <path
        d="M183.63 31H791.5A21.5 21.5 0 0 1 813 52.5V208H160V54.63A23.63 23.63 0 0 1 183.63 31Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={5}
      />
      <rect
        x={531.5}
        y={194.5}
        width={250.5}
        height={42.5}
        rx={10.65}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <rect
        x={787}
        y={147}
        width={38}
        height={51}
        rx={6.52}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <circle
        cx={615}
        cy={260}
        r={35.5}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <circle
        cx={709}
        cy={260}
        r={35.5}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M629.5 260.5a14 14 0 0 1-14 14c-18.57-.74-18.57-27.27 0-28a14 14 0 0 1 14 14ZM722.5 260.5a14 14 0 0 1-14 14c-18.57-.74-18.57-27.27 0-28a14 14 0 0 1 14 14Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeDasharray="2.93 2.93"
      />
      <rect
        x={184.5}
        y={214.5}
        width={32}
        height={65}
        rx={6.52}
        transform="rotate(90 200.5 247)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <circle
        cx={273}
        cy={260}
        r={35.5}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M287.5 260.5a14 14 0 0 1-14 14c-18.57-.74-18.57-27.27 0-28a14 14 0 0 1 14 14Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeDasharray="2.93 2.93"
      />
      <circle
        cx={126}
        cy={260}
        r={35.5}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
      <path
        d="M140.5 260.5a14 14 0 0 1-14 14c-18.57-.74-18.57-27.27 0-28a14 14 0 0 1 14 14Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeDasharray="2.93 2.93"
      />
      <rect
        x={52.42}
        y={196.84}
        width={282.58}
        height={42.5}
        rx={10.65}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <rect
        x={39}
        y={222}
        width={40}
        height={44}
        rx={6.52}
        transform="rotate(90 59 244)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <rect
        x={26}
        y={184}
        width={13}
        height={30}
        rx={6.5}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        d="m134 151 .5 23.5c.29 3.46.07 8.9-3.5 12.5s-9 3.77-16 4c-6.23.2-13.38-.12-13.38-.12a20.5 20.5 0 0 0-7.12.62 19.48 19.48 0 0 0-6 3c-7.62 5.58-6.73 13.18-15 20-4.55 3.75-7.87 4-9 4A12.57 12.57 0 0 1 57 216c-4.25-3.42-4.41-9.47-4.5-17.5-.13-11.4-.08-20.9 0-28M90 87h48v59H99l-12 12H62a178.35 178.35 0 0 1 28-71Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        d="m43 139 11-8 22-43c-2.49-4.08-2.64-7-2-9 4.34-13.8 50.93-9.67 58-9a26.05 26.05 0 0 1 12 8 26.68 26.68 0 0 1 6 18l1 113c-3.82-1.3-24-7.7-45 3a58.4 58.4 0 0 0-26 27H55a26.76 26.76 0 0 1-9-7 27.14 27.14 0 0 1-6-14 272.4 272.4 0 0 1 3-79Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4}
      />
    </svg>
  );
}
