import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import * as styles from "./header.module.scss";
import { motion } from "framer-motion";
import { useWindowScroll, useWindowSize } from "react-use";
import { useLocation } from "react-use";
import cx from "classnames";

function Header() {
  const { height } = useWindowSize();
  const { y } = useWindowScroll();
  const [hideNav, setHideNav] = useState(true);
  const currentLocation = useLocation();

  useEffect(() => {
    if (height - 250 > y && currentLocation.pathname === "/") {
      setHideNav(false);
    } else {
      setHideNav(true);
    }
  }, [height, y, currentLocation]);

  return (
    <nav className={styles.container}>
      <motion.div
        className={styles.pagelinks}
        animate={{
          backgroundColor: !hideNav
            ? "rgba(0, 12, 51, 0)"
            : "rgba(0, 12, 51, 1)",
        }}
        transition={{
          delay: 0.2,
          duration: 0.4,
          ease: [0.35, 0.1, 0.5, 1],
        }}
      >
        <motion.div
          className={styles.linkcontainer}
          animate={{
            y: !hideNav ? "-100%" : "0%",
            opacity: !hideNav ? 0 : 1,
          }}
          transition={{ duration: 0.4, ease: [0.35, 0.1, 0.5, 1] }}
        >
          <Link
            to="/indoortowtruck"
            className={styles.link}
            activeClassName={cx(styles.link, styles.active)}
          >
            Крытый эвакуатор
          </Link>
        </motion.div>

        <motion.div
          className={styles.linkcontainer}
          animate={{
            y: !hideNav ? "-100%" : "0%",
            opacity: !hideNav ? 0 : 1,
          }}
          transition={{
            duration: 0.4,
            ease: [0.35, 0.1, 0.5, 1],
            delay: 0.075,
          }}
        >
          <Link
            to="/carcarrier"
            className={styles.link}
            activeClassName={cx(styles.link, styles.active)}
          >
            Автовоз
          </Link>
        </motion.div>

        <motion.div
          className={styles.linkcontainer}
          animate={{
            y: !hideNav ? "-100%" : "0%",
            opacity: !hideNav ? 0 : 1,
          }}
          transition={{
            duration: 0.4,
            ease: [0.35, 0.1, 0.5, 1],
            delay: 0.125,
          }}
        >
          <Link
            to="/minicarcarrier"
            className={styles.link}
            activeClassName={cx(styles.link, styles.active)}
          >
            Мини автовоз
          </Link>
        </motion.div>

        <motion.div
          className={styles.linkcontainer}
          animate={{
            y: !hideNav ? "-100%" : "0%",
            opacity: !hideNav ? 0 : 1,
          }}
          transition={{
            duration: 0.4,
            ease: [0.35, 0.1, 0.5, 1],
            delay: 0.175,
          }}
        >
          <Link
            to="/transportationofthecar"
            className={styles.link}
            activeClassName={cx(styles.link, styles.active)}
          >
            Транспортировка авто
          </Link>
        </motion.div>

        <motion.div
          className={styles.linkcontainer}
          animate={{
            y: !hideNav ? "-100%" : "0%",
            opacity: !hideNav ? 0 : 1,
          }}
          transition={{
            duration: 0.4,
            ease: [0.35, 0.1, 0.5, 1],
            delay: 0.05,
          }}
        >
          <Link
            to="/directions"
            partiallyActive={true}
            className={styles.link}
            activeClassName={cx(styles.link, styles.active)}
          >
            Актуальные направления
          </Link>
        </motion.div>
      </motion.div>
    </nav>
  );
}

export default Header;
