import React, { useState, useEffect } from "react";
import { IoArrowUpOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import { useWindowScroll, useWindowSize } from "react-use";
import * as styles from "./scrolltop.module.scss";

function ScrollTop() {
  const { height } = useWindowSize();
  const { y } = useWindowScroll();
  const [hideButton, setHideButton] = useState(true);

  useEffect(() => {
    if (height * 1.75 > y) {
      setHideButton(false);
    } else {
      setHideButton(true);
    }
  }, [height, y]);

  return (
    <motion.button
      animate={{
        opacity: hideButton ? 0.9 : 0,
        scale: hideButton ? 1 : 0.9,
        y: hideButton ? 0 : 5,
      }}
      className={styles.btn}
      onClick={() => window.scrollTo(0, 0)}
    >
      <IoArrowUpOutline />
    </motion.button>
  );
}

export default ScrollTop;
