// extracted by mini-css-extract-plugin
export var active = "mobilemenu-module--active--yqIYg";
export var container = "mobilemenu-module--container--UC+1h";
export var footer = "mobilemenu-module--footer--y8-L4";
export var icon = "mobilemenu-module--icon--9H0Of";
export var label = "mobilemenu-module--label--oWA23";
export var layer = "mobilemenu-module--layer--MAcXu";
export var link = "mobilemenu-module--link--iOOGs";
export var linkhome = "mobilemenu-module--linkhome--aCAV2";
export var pagelinks = "mobilemenu-module--pagelinks--YNRKs";
export var services = "mobilemenu-module--services--qBxMD";
export var title = "mobilemenu-module--title--qzMqi";