import React, { useState } from "react";
import cx from "classnames";
import {
  IoShareSocialOutline,
  IoLogoVk,
  IoCloseSharp,
  IoLogoWhatsapp,
} from "react-icons/io5";
import * as styles from "./social.module.scss";
import { AnimatePresence, motion } from "framer-motion";

function Social() {
  const [isOpen, setisOpen] = useState(false);

  return (
    <div className={styles.container}>
      <AnimatePresence initial={false}>
        {isOpen && (
          <div className={styles.list}>
            <motion.a
              initial={{ opacity: 0, scale: 0.85 }}
              exit={{
                opacity: 0,
                scale: 0.85,
              }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: { delay: 0.075 },
              }}
              href="https://vk.com/avtovoz_logistic"
              target="blank"
              className={cx(styles.social, styles.open)}
            >
              <IoLogoVk className={styles.icon} />
            </motion.a>
            <motion.a
              initial={{ opacity: 0, scale: 0.9 }}
              exit={{ opacity: 0, scale: 0.9, transition: { delay: 0.075 } }}
              animate={{ opacity: 1, scale: 1 }}
              href="https://wa.me/79649749405"
              target="blank"
              className={cx(styles.social, styles.open)}
            >
              <IoLogoWhatsapp className={styles.icon} />
            </motion.a>
          </div>
        )}
      </AnimatePresence>
      <button
        className={cx(styles.social, isOpen && styles.open)}
        onClick={() => setisOpen(!isOpen)}
      >
        {isOpen ? (
          <IoCloseSharp className={styles.icon} />
        ) : (
          <IoShareSocialOutline className={styles.icon} />
        )}
      </button>
    </div>
  );
}

export default Social;
