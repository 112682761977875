import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { IoMenu, IoHomeOutline, IoCallOutline } from "react-icons/io5";
import cx from "classnames";
import { useLocation } from "react-use";
import * as styles from "./mobilenav.module.scss";
import Social from "./social";

function MobileNav({ openForm, formIsOpen, clickHome, openMenu, menuIsOpen }) {
  const currentLocation = useLocation();
  const [isHome, setIsHome] = useState(false);
  const [isService, setIsService] = useState(true);

  useEffect(() => {
    if (currentLocation.pathname === "/" && !formIsOpen && !menuIsOpen) {
      setIsHome(true);
    } else setIsHome(false);

    if (currentLocation.pathname === "/") {
      setIsService(true);
    } else setIsService(false);
  }, [currentLocation, formIsOpen, menuIsOpen]);

  return (
    <nav className={cx(styles.container, isService && styles.home)}>
      <Link
        to="/"
        className={cx(styles.btn, isHome && styles.active)}
        onClick={() => clickHome()}
      >
        <IoHomeOutline className={styles.icon} />
      </Link>
      {isService ? (
        <button
          className={cx(styles.btnlong, menuIsOpen && styles.active)}
          onClick={() => openMenu()}
        >
          <p>Наши услуги</p>
        </button>
      ) : (
        <button
          className={cx(styles.btnlong, formIsOpen && styles.active)}
          onClick={() => openForm()}
        >
          <p>стоимость</p>
        </button>
      )}
      <Social />
      <a href="tel:+78005551163" className={styles.btn}>
        <IoCallOutline className={styles.icon} />
      </a>
      {!isService && (
        <button
          className={cx(styles.btn, menuIsOpen && styles.active)}
          onClick={() => openMenu()}
        >
          <IoMenu className={styles.icon} />
        </button>
      )}
    </nav>
  );
}

export default MobileNav;
